/** Properties by which Block connections can be ordered. */
export enum BlockOrderField {
	FeaturedImagePosition = 'FEATURED_IMAGE_POSITION',
	HighlightPosition = 'HIGHLIGHT_POSITION',
	QueryPosition = 'QUERY_POSITION',
	UpdatedAt = 'UPDATED_AT',
}

/** BlockType is enum for the field block_type */
export enum BlockType {
	CustomPin = 'CUSTOM_PIN',
	CustomRegion = 'CUSTOM_REGION',
	Day = 'DAY',
	Destination = 'DESTINATION',
	Extra = 'EXTRA',
	Highlight = 'HIGHLIGHT',
	Itinerary = 'ITINERARY',
	List = 'LIST',
	Place = 'PLACE',
	Region = 'REGION',
	Requirements = 'REQUIREMENTS',
	Root = 'ROOT',
	Route = 'ROUTE',
	Source = 'SOURCE',
}

/** Properties by which Bundle connections can be ordered. */
export enum BundleOrderField {
	UpdatedAt = 'UPDATED_AT',
}

/** Properties by which Country connections can be ordered. */
export enum CountryOrderField {
	Continent = 'CONTINENT',
	ContinentCode = 'CONTINENT_CODE',
	Developed = 'DEVELOPED',
	Id = 'ID',
	Name = 'NAME',
	SubContinent = 'SUB_CONTINENT',
}

/** Properties by which Coupon connections can be ordered. */
export enum CouponOrderField {
	State = 'STATE',
	UpdatedAt = 'UPDATED_AT',
}

/** CouponState is enum for the field state */
export enum CouponState {
	Active = 'ACTIVE',
	Canceled = 'CANCELED',
	Expired = 'EXPIRED',
}

/** CustomColor is enum for the field color */
export enum CustomColor {
	Amber = 'AMBER',
	Blue = 'BLUE',
	Default = 'DEFAULT',
	Fuchsia = 'FUCHSIA',
	Green = 'GREEN',
	Indigo = 'INDIGO',
	Lime = 'LIME',
	Orange = 'ORANGE',
	Pink = 'PINK',
	Purple = 'PURPLE',
	Red = 'RED',
	Rose = 'ROSE',
	Sky = 'SKY',
	Teal = 'TEAL',
	Yellow = 'YELLOW',
}

/** CustomIcon is enum for the field icon */
export enum CustomIcon {
	AircraftChopper = 'AIRCRAFT_CHOPPER',
	AircraftHotAirBalloon = 'AIRCRAFT_HOT_AIR_BALLOON',
	AirportRoad = 'AIRPORT_ROAD',
	AmericanFootballBall = 'AMERICAN_FOOTBALL_BALL',
	AmericanFootballHelmet = 'AMERICAN_FOOTBALL_HELMET',
	AmusementParkCastle = 'AMUSEMENT_PARK_CASTLE',
	AmusementParkFerrisWheel = 'AMUSEMENT_PARK_FERRIS_WHEEL',
	AmusementParkRollercoaster = 'AMUSEMENT_PARK_ROLLERCOASTER',
	Anchor = 'ANCHOR',
	AnimalProductsCheese = 'ANIMAL_PRODUCTS_CHEESE',
	AnimalProductsDrumsticks = 'ANIMAL_PRODUCTS_DRUMSTICKS',
	AnimalProductsEggs = 'ANIMAL_PRODUCTS_EGGS',
	AnimalProductsFriedEgg = 'ANIMAL_PRODUCTS_FRIED_EGG',
	AnimalProductsTurkey = 'ANIMAL_PRODUCTS_TURKEY',
	ArcheryBow = 'ARCHERY_BOW',
	AsianFoodNoodles = 'ASIAN_FOOD_NOODLES',
	AsianFoodPorkBun = 'ASIAN_FOOD_PORK_BUN',
	AsianFoodRice = 'ASIAN_FOOD_RICE',
	BaggageCart = 'BAGGAGE_CART',
	BarbecueGrill = 'BARBECUE_GRILL',
	BaseballBatBall = 'BASEBALL_BAT_BALL',
	BaseballGlove = 'BASEBALL_GLOVE',
	BasketballBall = 'BASKETBALL_BALL',
	BeachPalmSunbed = 'BEACH_PALM_SUNBED',
	BeachParasolWater = 'BEACH_PARASOL_WATER',
	BeerMug = 'BEER_MUG',
	Bicycle = 'BICYCLE',
	BikingHelmetPerson = 'BIKING_HELMET_PERSON',
	BikingPerson = 'BIKING_PERSON',
	BoardGameDice = 'BOARD_GAME_DICE',
	BowlingPins = 'BOWLING_PINS',
	BowlingSet = 'BOWLING_SET',
	BreadBaguette = 'BREAD_BAGUETTE',
	BreadSlice = 'BREAD_SLICE',
	BreakfastCerealBowlSpoon = 'BREAKFAST_CEREAL_BOWL_SPOON',
	BreakfastCroissant = 'BREAKFAST_CROISSANT',
	BreakfastEnglish = 'BREAKFAST_ENGLISH',
	Bridge = 'BRIDGE',
	Bus = 'BUS',
	CableCar = 'CABLE_CAR',
	CakeCherry = 'CAKE_CHERRY',
	CampingRv = 'CAMPING_RV',
	CampingSleepingBag = 'CAMPING_SLEEPING_BAG',
	CampingTent = 'CAMPING_TENT',
	Canoe = 'CANOE',
	CanoePaddles = 'CANOE_PADDLES',
	Car = 'CAR',
	CardGameCardClub = 'CARD_GAME_CARD_CLUB',
	CarGarage = 'CAR_GARAGE',
	CarRepairTowTruck = 'CAR_REPAIR_TOW_TRUCK',
	CasinoChip = 'CASINO_CHIP',
	CasinoLuckyHorseshoe = 'CASINO_LUCKY_HORSESHOE',
	CatSitting = 'CAT_SITTING',
	ChampagneBottleGlass = 'CHAMPAGNE_BOTTLE_GLASS',
	ChampagneCheers = 'CHAMPAGNE_CHEERS',
	ChefGearHat = 'CHEF_GEAR_HAT',
	ChessFigures = 'CHESS_FIGURES',
	CircusTent = 'CIRCUS_TENT',
	CleanCarChargingCable = 'CLEAN_CAR_CHARGING_CABLE',
	CleanCarGas = 'CLEAN_CAR_GAS',
	ClimbingMountain = 'CLIMBING_MOUNTAIN',
	CocktailCoconut = 'COCKTAIL_COCONUT',
	CocktailMartini = 'COCKTAIL_MARTINI',
	CoffeeCold = 'COFFEE_COLD',
	CoffeeCup = 'COFFEE_CUP',
	CoffeeCupHot = 'COFFEE_CUP_HOT',
	CoffeeStraw = 'COFFEE_STRAW',
	ConcertRock = 'CONCERT_ROCK',
	ConcertSing = 'CONCERT_SING',
	Cookie = 'COOKIE',
	Cupcake = 'CUPCAKE',
	DancingBallet = 'DANCING_BALLET',
	Default = 'DEFAULT',
	DivingMask = 'DIVING_MASK',
	DivingOxygenTank = 'DIVING_OXYGEN_TANK',
	DogBone = 'DOG_BONE',
	DogSit = 'DOG_SIT',
	ExoticFoodKebab = 'EXOTIC_FOOD_KEBAB',
	ExoticFoodOyster = 'EXOTIC_FOOD_OYSTER',
	FarmingBarn = 'FARMING_BARN',
	FarmingBarnSilo = 'FARMING_BARN_SILO',
	FarmingFieldSun = 'FARMING_FIELD_SUN',
	FastFoodBurger = 'FAST_FOOD_BURGER',
	FastFoodDoubleBurger = 'FAST_FOOD_DOUBLE_BURGER',
	FastFoodFrenchFries = 'FAST_FOOD_FRENCH_FRIES',
	FastFoodHotDog = 'FAST_FOOD_HOT_DOG',
	FastFoodPizza = 'FAST_FOOD_PIZZA',
	FastFoodPizzaSlice = 'FAST_FOOD_PIZZA_SLICE',
	FastFoodPretzel = 'FAST_FOOD_PRETZEL',
	FastFoodTacos = 'FAST_FOOD_TACOS',
	FastFoodWrap = 'FAST_FOOD_WRAP',
	Fireworks = 'FIREWORKS',
	FishingFish = 'FISHING_FISH',
	Flower = 'FLOWER',
	FruitApple = 'FRUIT_APPLE',
	FruitBanana = 'FRUIT_BANANA',
	FruitCherry = 'FRUIT_CHERRY',
	FruitGrapes = 'FRUIT_GRAPES',
	FruitLemon = 'FRUIT_LEMON',
	FruitStrawberry = 'FRUIT_STRAWBERRY',
	FruitWatermelon = 'FRUIT_WATERMELON',
	GasLoad = 'GAS_LOAD',
	GolfBall = 'GOLF_BALL',
	GolfEquipment = 'GOLF_EQUIPMENT',
	GolfHole = 'GOLF_HOLE',
	GolfHoleAim = 'GOLF_HOLE_AIM',
	HistoricalBuildingTower = 'HISTORICAL_BUILDING_TOWER',
	HockeyPuckStick = 'HOCKEY_PUCK_STICK',
	HotelDoubleBed = 'HOTEL_DOUBLE_BED',
	HotelSingleBed = 'HOTEL_SINGLE_BED',
	IceCreamBite = 'ICE_CREAM_BITE',
	IceCreamBowl = 'ICE_CREAM_BOWL',
	IceCreamCone = 'ICE_CREAM_CONE',
	Jellyfish = 'JELLYFISH',
	Lighthouse = 'LIGHTHOUSE',
	LivestockCow = 'LIVESTOCK_COW',
	LivestockRam = 'LIVESTOCK_RAM',
	MarineMammalTurtle = 'MARINE_MAMMAL_TURTLE',
	MartialArtsSumo = 'MARTIAL_ARTS_SUMO',
	MartialArtsSwords = 'MARTIAL_ARTS_SWORDS',
	Moose = 'MOOSE',
	NauticSportsSailingPerson = 'NAUTIC_SPORTS_SAILING_PERSON',
	NauticSportsScooter = 'NAUTIC_SPORTS_SCOOTER',
	NauticSportsSurfing = 'NAUTIC_SPORTS_SURFING',
	OfficialBuilding = 'OFFICIAL_BUILDING',
	OutdoorsBackpack = 'OUTDOORS_BACKPACK',
	OutdoorsBarbeque = 'OUTDOORS_BARBEQUE',
	OutdoorsCampfire = 'OUTDOORS_CAMPFIRE',
	OutdoorsHorse = 'OUTDOORS_HORSE',
	OutdoorsKiteFlying = 'OUTDOORS_KITE_FLYING',
	OutdoorsTreeValley = 'OUTDOORS_TREE_VALLEY',
	ParalympicsSwimming = 'PARALYMPICS_SWIMMING',
	ParalympicsWeightlifting = 'PARALYMPICS_WEIGHTLIFTING',
	ParkBench = 'PARK_BENCH',
	Parrot = 'PARROT',
	PartyConfetti = 'PARTY_CONFETTI',
	PartyMask = 'PARTY_MASK',
	Passport = 'PASSPORT',
	PastaBowlWarm = 'PASTA_BOWL_WARM',
	PastaNoodles = 'PASTA_NOODLES',
	PetsPaw = 'PETS_PAW',
	Pie = 'PIE',
	PingPongPaddle = 'PING_PONG_PADDLE',
	Plane = 'PLANE',
	PlaneLand = 'PLANE_LAND',
	PlaneTakeOff = 'PLANE_TAKE_OFF',
	PoolBlackBall = 'POOL_BLACK_BALL',
	PublicServiceAmbulance = 'PUBLIC_SERVICE_AMBULANCE',
	PublicServicePolice = 'PUBLIC_SERVICE_POLICE',
	RailroadTrain = 'RAILROAD_TRAIN',
	RestaurantEatingSet = 'RESTAURANT_EATING_SET',
	RestaurantForkSpoon = 'RESTAURANT_FORK_SPOON',
	Riceball = 'RICEBALL',
	Rollerblades = 'ROLLERBLADES',
	Rooster = 'ROOSTER',
	SailingBoat = 'SAILING_BOAT',
	SaunaHeatStone = 'SAUNA_HEAT_STONE',
	Scooter = 'SCOOTER',
	SeafoodCrab = 'SEAFOOD_CRAB',
	SeafoodFish = 'SEAFOOD_FISH',
	SeafoodShrimp = 'SEAFOOD_SHRIMP',
	Shark = 'SHARK',
	ShootingRifleTarget = 'SHOOTING_RIFLE_TARGET',
	Shop = 'SHOP',
	ShoppingBasketHandle = 'SHOPPING_BASKET_HANDLE',
	ShoppingCartEmpty = 'SHOPPING_CART_EMPTY',
	ShowTheaterMasks = 'SHOW_THEATER_MASKS',
	SkatingShoes = 'SKATING_SHOES',
	SkiingBoardSlide = 'SKIING_BOARD_SLIDE',
	SkiingCableCar = 'SKIING_CABLE_CAR',
	SkiingSnowboard = 'SKIING_SNOWBOARD',
	SoccerBall = 'SOCCER_BALL',
	SoftDrinksGlass = 'SOFT_DRINKS_GLASS',
	SpaLotus = 'SPA_LOTUS',
	SpaStone = 'SPA_STONE',
	Spirits = 'SPIRITS',
	SpiritsGlass = 'SPIRITS_GLASS',
	SportHockey = 'SPORT_HOCKEY',
	SportHorseRiding = 'SPORT_HORSE_RIDING',
	SportParagliding = 'SPORT_PARAGLIDING',
	Sushi = 'SUSHI',
	SwanCouple = 'SWAN_COUPLE',
	SwimmingJump = 'SWIMMING_JUMP',
	Taxi = 'TAXI',
	TeaKettle = 'TEA_KETTLE',
	TennisRacquet = 'TENNIS_RACQUET',
	Trailer = 'TRAILER',
	TrekkingPerson = 'TREKKING_PERSON',
	Truck = 'TRUCK',
	TukTuk = 'TUK_TUK',
	VegetablesCarrot = 'VEGETABLES_CARROT',
	VegetablesCorn = 'VEGETABLES_CORN',
	VegetablesSalad = 'VEGETABLES_SALAD',
	VolleyballBall = 'VOLLEYBALL_BALL',
	WhaleTail = 'WHALE_TAIL',
	WildBirdFly = 'WILD_BIRD_FLY',
	WineGlass = 'WINE_GLASS',
	WineGlassBottle = 'WINE_GLASS_BOTTLE',
}

/** Properties by which ExternalSource connections can be ordered. */
export enum ExternalSourceOrderField {
	UpdatedAt = 'UPDATED_AT',
}

/** Properties by which Impression connections can be ordered. */
export enum ImpressionOrderField {
	UpdatedAt = 'UPDATED_AT',
}

/** Properties by which Invitation connections can be ordered. */
export enum InvitationOrderField {
	Status = 'STATUS',
	UpdatedAt = 'UPDATED_AT',
}

/** InvitationStatus is enum for the field status */
export enum InvitationStatus {
	Accepted = 'ACCEPTED',
	Expired = 'EXPIRED',
	Sent = 'SENT',
}

/** Properties by which Listing connections can be ordered. */
export enum ListingOrderField {
	PublishedAt = 'PUBLISHED_AT',
	Rank = 'RANK',
	State = 'STATE',
	UpdatedAt = 'UPDATED_AT',
}

/** ListingPublishStatus is enum for the field publish_status */
export enum ListingPublishStatus {
	Abandoned = 'ABANDONED',
	Archived = 'ARCHIVED',
	Draft = 'DRAFT',
	Expired = 'EXPIRED',
	Published = 'PUBLISHED',
	Review = 'REVIEW',
	Unpublished = 'UNPUBLISHED',
}

/** Properties by which Location connections can be ordered. */
export enum LocationOrderField {
	UpdatedAt = 'UPDATED_AT',
}

/** LocationPOIType is enum for the field poi_type */
export enum LocationPoiType {
	Airport = 'Airport',
	Business = 'Business',
	Hotel = 'Hotel',
	Place = 'Place',
	Restaurant = 'Restaurant',
}

/** LocationType is enum for the field type */
export enum LocationType {
	Address = 'Address',
	Continent = 'Continent',
	Country = 'Country',
	District = 'District',
	Locality = 'Locality',
	Neighborhood = 'Neighborhood',
	Poi = 'POI',
	Place = 'Place',
	Postcode = 'Postcode',
	Region = 'Region',
}

/** Properties by which Media connections can be ordered. */
export enum MediaOrderField {
	UpdatedAt = 'UPDATED_AT',
}

/** MediaType is enum for the field media_type */
export enum MediaType {
	Document = 'DOCUMENT',
	Image = 'IMAGE',
	Video = 'VIDEO',
}

/** Properties by which MemberProfile connections can be ordered. */
export enum MemberProfileOrderField {
	UpdatedAt = 'UPDATED_AT',
}

/** MemberProfileRole is enum for the field role */
export enum MemberProfileRole {
	Agent = 'AGENT',
	Observer = 'OBSERVER',
	Participant = 'PARTICIPANT',
}

/** NotificationChannel is enum for the field channel */
export enum NotificationChannel {
	Email = 'EMAIL',
	MobilePush = 'MOBILE_PUSH',
	Sms = 'SMS',
	WebPush = 'WEB_PUSH',
}

/** NotificationDeliveryStatus is enum for the field delivery_status */
export enum NotificationDeliveryStatus {
	Blocked = 'BLOCKED',
	Bounced = 'BOUNCED',
	Deferred = 'DEFERRED',
	Delivered = 'DELIVERED',
	Dropped = 'DROPPED',
	Pending = 'PENDING',
	Processed = 'PROCESSED',
	Scheduled = 'SCHEDULED',
}

/** NotificationEngagementStatus is enum for the field engagement_status */
export enum NotificationEngagementStatus {
	Clicked = 'CLICKED',
	Opened = 'OPENED',
	Pending = 'PENDING',
	Spammed = 'SPAMMED',
	Unsubscribed = 'UNSUBSCRIBED',
}

/** Properties by which Notification connections can be ordered. */
export enum NotificationOrderField {
	UpdatedAt = 'UPDATED_AT',
}

/** NotificationType is enum for the field type */
export enum NotificationType {
	Other = 'OTHER',
	Purchase = 'PURCHASE',
	Review = 'REVIEW',
}

/** Possible directions in which to order a list of items when provided an `orderBy` argument. */
export enum OrderDirection {
	/** Specifies an ascending order for a given `orderBy` argument. */
	Asc = 'ASC',
	/** Specifies a descending order for a given `orderBy` argument. */
	Desc = 'DESC',
}

/** ProcedureCategory is enum for the field category */
export enum ProcedureCategory {
	Covid_19Test = 'COVID_19_TEST',
	DepositRequired = 'DEPOSIT_REQUIRED',
	DocRequired = 'DOC_REQUIRED',
	DocRequirement = 'DOC_REQUIREMENT',
	HealthAssessment = 'HEALTH_ASSESSMENT',
	HealthInsurance = 'HEALTH_INSURANCE',
	HealthMeasures = 'HEALTH_MEASURES',
	NoCovid_19Test = 'NO_COVID_19_TEST',
	NoQuarantine = 'NO_QUARANTINE',
	Ppe = 'PPE',
	Quarantine = 'QUARANTINE',
	ReEntryPermit = 'RE_ENTRY_PERMIT',
	Sanitization = 'SANITIZATION',
	TravelInsurance = 'TRAVEL_INSURANCE',
	Vaccination = 'VACCINATION',
}

/** ProcedureEnforcement is enum for the field enforcement */
export enum ProcedureEnforcement {
	Mandatory = 'MANDATORY',
	MayBeRequired = 'MAY_BE_REQUIRED',
	NotRequired = 'NOT_REQUIRED',
	Optional = 'OPTIONAL',
	Recommended = 'RECOMMENDED',
	Unknown = 'UNKNOWN',
}

/** Properties by which Procedure connections can be ordered. */
export enum ProcedureOrderField {
	Category = 'CATEGORY',
	End = 'END',
	LastChecked = 'LAST_CHECKED',
	Stage = 'STAGE',
	Start = 'START',
	StillCurrentAt = 'STILL_CURRENT_AT',
	UpdatedAt = 'UPDATED_AT',
}

/** ProcedureStage is enum for the field stage */
export enum ProcedureStage {
	BeforeArrival = 'BEFORE_ARRIVAL',
	BeforeDeparture = 'BEFORE_DEPARTURE',
	Domestic = 'DOMESTIC',
	InAirport = 'IN_AIRPORT',
	InFlight = 'IN_FLIGHT',
	OnArrival = 'ON_ARRIVAL',
}

/** Properties by which Purchase connections can be ordered. */
export enum PurchaseOrderField {
	PaidAt = 'PAID_AT',
	State = 'STATE',
	UpdatedAt = 'UPDATED_AT',
}

/** PurchasePurchaseType is enum for the field purchase_type */
export enum PurchasePurchaseType {
	Guide = 'GUIDE',
	ListingFee = 'LISTING_FEE',
}

/** PurchaseState is enum for the field state */
export enum PurchaseState {
	Confirmed = 'CONFIRMED',
	Confirming = 'CONFIRMING',
	ErrorSupportRequired = 'ERROR_SUPPORT_REQUIRED',
	ErrorUserSelectionRequired = 'ERROR_USER_SELECTION_REQUIRED',
	Pending = 'PENDING',
	Refunded = 'REFUNDED',
	RequiresPaymentMethod = 'REQUIRES_PAYMENT_METHOD',
}

/** Properties by which Replica connections can be ordered. */
export enum ReplicaOrderField {
	UpdatedAt = 'UPDATED_AT',
}

/** RestrictionCategory is enum for the field category */
export enum RestrictionCategory {
	Boarding = 'BOARDING',
	Entry = 'ENTRY',
	Exit = 'EXIT',
	InFlight = 'IN_FLIGHT',
	OnArrival = 'ON_ARRIVAL',
	Transit = 'TRANSIT',
}

/** RestrictionDirectionality is enum for the field directionality */
export enum RestrictionDirectionality {
	Less = 'LESS',
	More = 'MORE',
	NoChange = 'NO_CHANGE',
}

/** Properties by which Restriction connections can be ordered. */
export enum RestrictionOrderField {
	End = 'END',
	LastChecked = 'LAST_CHECKED',
	Start = 'START',
	StillCurrentAt = 'STILL_CURRENT_AT',
	UpdatedAt = 'UPDATED_AT',
}

/** RestrictionSubCategory is enum for the field sub_category */
export enum RestrictionSubCategory {
	Boarding = 'BOARDING',
	Entry = 'ENTRY',
	Exit = 'EXIT',
	NoEntry = 'NO_ENTRY',
	NoExit = 'NO_EXIT',
	NoRestriction = 'NO_RESTRICTION',
	RestrictedEntry = 'RESTRICTED_ENTRY',
	RestrictedExit = 'RESTRICTED_EXIT',
	Transit = 'TRANSIT',
}

/** Properties by which Review connections can be ordered. */
export enum ReviewOrderField {
	UpdatedAt = 'UPDATED_AT',
}

/** RouteMode is enum for the field mode */
export enum RouteMode {
	Bike = 'BIKE',
	Bus = 'BUS',
	Drive = 'DRIVE',
	Ferry = 'FERRY',
	Motorcycle = 'MOTORCYCLE',
	Subway = 'SUBWAY',
	Train = 'TRAIN',
	Tram = 'TRAM',
	Walk = 'WALK',
}

/** Properties by which Score connections can be ordered. */
export enum ScoreOrderField {
	UpdatedAt = 'UPDATED_AT',
}

/** SourceType is enum for the field type */
export enum SourceType {
	Activity = 'ACTIVITY',
	Airport = 'AIRPORT',
	Download = 'DOWNLOAD',
	Government = 'GOVERNMENT',
	Instruction = 'INSTRUCTION',
	Link = 'LINK',
	Location = 'LOCATION',
	Lodging = 'LODGING',
	Transportation = 'TRANSPORTATION',
	Unknown = 'UNKNOWN',
}

/** Properties by which Trip connections can be ordered. */
export enum TripOrderField {
	DepartAt = 'DEPART_AT',
	ReturnAt = 'RETURN_AT',
	UpdatedAt = 'UPDATED_AT',
}

export enum TripPaginationFilter {
	Booking = 'Booking',
	Completed = 'Completed',
	Current = 'Current',
	Planning = 'Planning',
	Published = 'Published',
	Purchased = 'Purchased',
	Upcoming = 'Upcoming',
}

/** Properties by which User connections can be ordered. */
export enum UserOrderField {
	UpdatedAt = 'UPDATED_AT',
}

/** VaccinationStatus is enum for the field vaccination */
export enum VaccinationStatus {
	FullyVaccinated = 'FULLY_VACCINATED',
	NotSpecified = 'NOT_SPECIFIED',
	NotVaccinated = 'NOT_VACCINATED',
}
